import ApiService from "@/core/services/ApiService";
import {
  LoginPayload,
  LoginResponse,
} from "@/core/types/application/Authentication";
import { ApiResponse, ErrorItem } from "@/core/types/misc/ApiResponse";
import JwtService from "@/core/services/JwtService";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { base64Decode } from "@/core/helpers/encryption";
import { UserInfo } from "@/core/types/application/UserInfo";
import { ActiveSession } from "@/core/types/application/ActiveSession";
import { ForgotPassword } from "@/core/types/application/ForgotPassword";
import { reformatMenu } from "@/core/helpers/helpers-utils";
import { UserProfile } from "@/core/types/application/UserProfile";

export const useAuthenticationStore = defineStore({
  id: "authentication",
  state: () => {
    return {
      stateUser: {} as UserInfo,
      stateUserMenu: {} as any,
      stateUserPermission: [] as any,
      stateUserProfile: {} as UserProfile,
      stateActiveSession: {} as ActiveSession,
      stateForgotSession: {} as ForgotPassword,
      stateLoggedIn: false,
      stateLoading: false,
      stateLoginPayload: {
        username: "",
        password: "",
      } as LoginPayload,
      stateError: false,
      stateErrors: [] as ErrorItem[],
      stateErrorMsg: "",
      stateDashboardName: "",
    };
  },
  getters: {
    user: (state) => {
      return state.stateUser;
    },
    userMenu: (state) => {
      return state.stateUserMenu;
    },
    userProfile: (state) => {
      return state.stateUserProfile;
    },
    userPermission: (state) => {
      return state.stateUserPermission;
    },
    activeSession: (state) => {
      return state.stateActiveSession;
    },
    loading: (state) => {
      return state.stateLoading;
    },
    isError: (state) => {
      return state.stateError;
    },
    loginPayload: (state) => {
      return state.stateLoginPayload;
    },
    isLoggedIn: (state) => {
      return state.stateLoggedIn;
    },
  },
  actions: {
    setLoading(value: boolean) {
      this.stateLoading = value;
    },
    setIsLoggedIn() {
      this.stateLoggedIn = true;
    },
    async signIn() {
      this.setLoading(true);
      this.stateLoggedIn = false;
      const payload = {
        username: this.stateLoginPayload.username,
        password: this.stateLoginPayload.password,
      } as LoginPayload;
      try {
        const response: AxiosResponse<ApiResponse<LoginResponse>> =
          await ApiService.postAuth(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/auth/sign-in`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          const token = response.data.data.token;
          const accHash = token.split(".")[1];
          localStorage.setItem("acc-info", accHash);
          JwtService.saveToken(response.data.data.token);
          this.stateLoggedIn = true;
        } else {
          this.stateError = true;
          this.stateErrorMsg = response.data.meta.message;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
      this.setLoading(false);
    },
    async forgotPassword() {
      this.stateError = false;
      this.setLoading(true);
      this.stateLoggedIn = false;
      const payload = {
        email: this.stateForgotSession.email,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.postAuth(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/auth/password/forgot`,
            payload as AxiosRequestConfig
          );
        if (response.status === 200) {
          this.stateForgotSession.sessionId = response.data.data.id;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
      this.setLoading(false);
    },
    async forgotPasswordValidate() {
      this.stateError = false;
      this.setLoading(true);
      this.stateLoggedIn = false;
      const payload = {
        id: this.stateForgotSession.sessionId,
        otp: this.stateForgotSession.token,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.postAuth(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/auth/password/otp/validate`,
            payload as AxiosRequestConfig
          );
        if (response.status === 204) {
          this.stateForgotSession.isValid = true;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
      this.setLoading(false);
    },
    async forgotPasswordReset() {
      this.stateError = false;
      this.setLoading(true);
      this.stateLoggedIn = false;
      const payload = {
        id: this.stateForgotSession.sessionId,
        password: this.stateForgotSession.newPasswordConf,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.postAuth(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/auth/password/reset`,
            payload as AxiosRequestConfig
          );
        if (response.status === 204) {
          this.stateForgotSession.isValid = true;
          this.stateForgotSession = {} as ForgotPassword;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
      this.setLoading(false);
    },

    async getUserMenu() {
      this.stateUserMenu = [];
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `roles/menus`
        );

        this.stateUserMenu = reformatMenu(response.data.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getUserPermission(role_id) {
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `roles/${role_id}/permissions`
        );
        this.stateUserPermission = response.data.data;
        localStorage.setItem(
          "acc-permission",
          JSON.stringify(response.data.data)
        );
      } catch (error) {
        console.log(error);
      }
    },
    async getUserProfile() {
      try {
        const accHash = localStorage.getItem("acc-info") as string;
        const accInfo = JSON.parse(base64Decode(accHash)) as UserInfo;
        const response: AxiosResponse<ApiResponse<UserProfile>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `users/${accInfo.sub}`
        );
        this.stateUserProfile = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    // async forgotPasswordReset() {},
    // async forgotPasswordGenerate() {},
    getUserInfo() {
      const accHash = localStorage.getItem("acc-info") as string;
      const accInfo = JSON.parse(base64Decode(accHash)) as UserInfo;
      this.stateUser = accInfo;
      if (this.stateUser.role_id) {
        this.getUserPermission(this.stateUser.role_id);
      }
      if (this.stateUser.org_type === "SUPER_ADMIN") {
        this.stateActiveSession = {
          name: "Super Admin",
          dashboardName: "Superadmin Dashboard",
          activePage: "super-admin",
        };
      } else if (this.stateUser.org_type === "AGENT_P3MI") {
        this.stateActiveSession = {
          name: "Agent",
          dashboardName: "Agent Dashboard",
          activePage: "agent",
        };
      } else if (this.stateUser.org_type === "MEDICAL") {
        this.stateActiveSession = {
          name: "Medical",
          dashboardName: "Medical Dashboard",
          activePage: "medical",
        };
      } else if (this.stateUser.org_type === "JOB_FAIR") {
        this.stateActiveSession = {
          name: "Job Fair",
          dashboardName: "Jobfair Dashboard",
          activePage: "jobfair",
        };
      } else if (this.stateUser.org_type === "P3MI") {
        this.stateActiveSession = {
          name: "P3MI",
          dashboardName: "P3MI Dashboard",
          activePage: "p3mi",
        };
      } else if (this.stateUser.org_type === "SYARIKAH") {
        this.stateActiveSession = {
          name: "Syarikah",
          dashboardName: "Syarikah Dashboard",
          activePage: "syarikah",
        };
      } else if (this.stateUser.org_type === "VFS") {
        this.stateActiveSession = {
          name: "VFS",
          dashboardName: "VFS Dashboard",
          activePage: "",
        };
      } else if (this.stateUser.org_type === "POLRI") {
        this.stateActiveSession = {
          name: "Polri",
          dashboardName: "Polri Dashboard",
          activePage: "polri",
        };
      } else if (this.stateUser.org_type === "LSP") {
        this.stateActiveSession = {
          name: "Lsp",
          dashboardName: "LSP Dashboard",
          activePage: "lsp",
        };
      } else if (this.stateUser.org_type === "BLK") {
        this.stateActiveSession = {
          name: "Blk",
          dashboardName: "BLK Dashboard",
          activePage: "blk",
        };
      }
    },
  },
});
